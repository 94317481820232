<template>
  <v-main>
    <v-container fluid page>
      <v-form ref="form">
        <v-row align="center">
          <v-col cols="12">
            <h2 class="display-1 font-weight-light pt-5 pl-5">
              {{ $t('navigation.contactInformation') }}
            </h2>
          </v-col>

          <v-col cols="12" v-if="error">
            <v-alert border="left" colored-border type="error" elevation="2">
              <strong v-if="code">{{ code }}</strong>
              {{ message }}
            </v-alert>
          </v-col>

          <v-col cols="12" v-if="showAlert === true">
            <v-alert
              border="left"
              colored-border
              type="success"
              elevation="2"
              dismissible
              @input="showAlert = false"
            >
              <strong>201</strong>
              Los datos de contacto se han actualizado correctamente.
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3 class="headline">Información de contacto</h3>
                  </v-col>
                  <!-- Restaurant's name -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      v-model="name"
                      label="Nombre restaurante"
                      :rules="rules.name"
                      counter="150"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's phone number -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="Número de teléfono"
                      v-model="phone_number"
                      :rules="rules.phone_number"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's email needed for Stripe -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="Correo electrónico"
                      v-model="email"
                      :rules="rules.email"
                    ></v-text-field>
                  </v-col>

                  <!-- Restaurant's nif -->
                  <v-col cols="12" md="6" xl="3">
                    <v-text-field
                      label="NIF"
                      v-model="nif"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <h3 class="headline">Dirección</h3>
                  </v-col>

                  <!-- Restaurant's address -->
                  <v-col cols="12" md="4">
                    <v-combobox
                      v-model="road_type"
                      :items="roadTypes"
                      label="Selecciona un tipo de vía"
                      item-value="id"
                      item-text="road_key"
                      :loading="loadingTypes"
                      :rules="rules.road_type"
                      @focus="loadTypes"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-text-field
                      label="Nombre de vía"
                      v-model="street"
                      counter="250"
                      :rules="rules.street"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Número"
                      v-model="number"
                      :rules="rules.number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Piso"
                      v-model="floor"
                      :rules="rules.floor"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Portal"
                      v-model="portal"
                      counter="20"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Puerta"
                      v-model="doorway"
                      counter="20"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Otros datos"
                      v-model="other_data"
                      counter="200"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Código postal"
                      v-model="postal_code"
                      :rules="rules.postal_code"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Provincia"
                      v-model="municipality"
                      counter="100"
                      :rules="rules.municipality"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="Ciudad"
                      v-model="city"
                      counter="100"
                      :rules="rules.city"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="3">
                    <v-text-field
                      label="País"
                      v-model="country"
                      counter="100"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <!-- Buttons -->
                  <v-col cols="12" class="text-right">
                    <v-btn color="primary" @click.stop="save" class="ma-2"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';
import goTo from 'vuetify/lib/services/goto';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';
import { email } from '@/validators/email';

import EximyrLoading from '../components/EximyrLoading';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
  },

  data: () => ({
    name: '',

    phone_number: '',
    email: '',
    nif: '',

    roadTypes: [],
    road_type: null,
    loadingTypes: false,
    loading: false,

    street: '',
    number: '',
    floor: '',
    portal: '',
    doorway: '',
    other_data: '',
    postal_code: '',
    municipality: '',
    city: '',
    country: '',

    showAlert: false,
  }),

  computed: {
    ...mapGetters('restaurants', {
      restaurant: 'restaurant',
    }),

    // Validation errors
    rules() {
      return {
        name: [required(this), length(this, 150)],
        phone_number: [required(this), numeric(this)],
        email: [required(this), email(this)],
        number: [required(this)],
        floor: [required(this)],
        street: [required(this)],
        postal_code: [required(this)],
        road_type: [required(this)],
        city: [required(this)],
        municipality: [required(this)],
      };
    },
  },

  created() {
    // Load current restaurant data
    this.loading = true;
    this.$store
      .dispatch('restaurants/loadRestaurant', this.$route.params.restaurant)
      .then(response => {
        this.name = response.name;

        this.phone_number = response.phone_number;
        this.email = response.email;
        this.nif = response.nif;

        if (response.address) {
          this.road_type = response.address.type;
          this.roadTypes = [this.road_type];
          this.street = response.address.street;
          this.number = response.address.number;
          this.postal_code = response.address.postal_code;
          this.floor = response.address.floor;
          this.portal = response.address.portal;
          this.doorway = response.address.doorway;
          this.other_data = response.address.other_data;
          this.municipality = response.address.municipality;
          this.city = response.address.city;
          this.country = response.address.country;
        }
      })
      .catch(error => {
        this.storeError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    //
    loadTypes() {
      this.clearErrors();

      if (this.roadTypes.length > 1) {
        return;
      }
      this.loadingTypes = true;

      // Load all road types
      this.$store
        .dispatch('restaurants/getRoadTypes')
        .then(response => {
          this.clearErrors();

          this.roadTypes = response.data;

          if (this.road_type != null) {
            this.road_type = this.roadTypes.find(
              t => t.id === this.road_type.id
            );
          }
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loadingTypes = false;
        });
    },

    save() {
      this.clearErrors();

      // Disable the alert
      this.showAlert = false;

      // Revalidate the form
      if (false == this.$refs.form.validate()) {
        goTo(0);
        return;
      }

      const payload = {
        restaurant: this.$route.params.restaurant,

        name: this.name,
        phone_number: this.phone_number,
        email: this.email,

        address: {
          road_type_id: this.road_type.id,
          street: this.street,
          number: this.number,
          postal_code: this.postal_code,
          floor: this.floor,
          portal: this.portal,
          doorway: this.doorway,
          other_data: this.other_data,
          municipality: this.municipality,
          city: this.city,
          country: this.country ? this.country : 'España',
        },
      };

      this.loading = true;

      this.$store
        .dispatch('restaurants/update', payload)
        .then(() => {
          this.showAlert = true;
        })
        .catch(error => {
          this.storeError(error);
        })
        .finally(() => {
          this.loading = false;
          goTo(0);
        });
    },
  },
};
</script>
